import { useContext, useEffect, useState } from "react";
import requestClient from "../../../../external/request-client-api/RequestClient";
import { AppContext } from "../../../../external/store/AppContext";
import Switch from "../../switch/Switch";
import ProfileSectionStyle from "./ProfileSection.css";

import { FaExchangeAlt } from "react-icons/fa";
import { BiRefresh } from "react-icons/bi";
import LoadingBar from "../../loading-bar/LoadingBar";
import { revalidatePath } from "../../../../external/global-function/global-function";

const ProfileSection = () => {
  const [slug, setSlug] = useState("");
  const [pageName, setPageName] = useState("");
  const [positionName, setPositionName] = useState("");
  const [pageStatus, setPageStatus] = useState(false);
  const [greetingMessages, setGreetingMessages] = useState("");
  const [avatar, setAvatar] = useState("");
  const [linkContact, setLinkContact] = useState("");
  const [linkEmail, setLinkEmail] = useState("");
  const [projectPictureRatio, setProjectPictureRatio] = useState(0.55);
  const [statistic, setStatistic] = useState([]);
  const [activities, setActivities] = useState([]);

  const appContext = useContext(AppContext);

  useEffect(() => {
    requestClient.get(`page/user`).then(({ data }) => {
      setSlug(data.data.slug);
      setAvatar(data.data.avatar);
      setPageName(data.data.page_name);
      setPageStatus(data.data.status == 1);
      setGreetingMessages(data.data.greeting_messages ?? "");
      setLinkContact(data.data.link_contact ?? "");
      setPositionName(data.data.position ?? "");
      setLinkEmail(data.data.link_email ?? "");
      setProjectPictureRatio(data.data.picture_width_ratio ?? 0.55);
    });

    requestClient.get(`page/statistic`).then(({ data }) => {
      setStatistic(data.data);
    });

    getActivityData();
  }, []);

  const getActivityData = () => {
    requestClient.get(`page/activities`).then(({ data }) => {
      setActivities(data.data);
      revalidatePath(slug);
    });
  };

  const onChangeSlug = () => {
    requestClient.put("page/slug", { slug }).then(({ data }) => {
      appContext.showWarning(data.message, "success");
      revalidatePath(slug);
    });
  };

  const onChangeAvatar = () => {
    requestClient.put("page/avatar", { avatar }).then(({ data }) => {
      appContext.showWarning(data.message, "success");
      revalidatePath(slug);
    });
  };

  const onChangePageName = () => {
    requestClient.put("page/name", { page_name: pageName }).then(({ data }) => {
      appContext.showWarning(data.message, "success");
      revalidatePath(slug);
    });
  };

  const onChangePositionName = () => {
    requestClient
      .put("page/position", { position: positionName })
      .then(({ data }) => {
        appContext.showWarning(data.message, "success");
        revalidatePath(slug);
      });
  };

  const onChangePageStatus = async (val) => {
    try {
      const { data } = await requestClient.put("page/status", {
        status: val ? 1 : 0,
      });
      appContext.showWarning(data.message, "success");
      revalidatePath(slug);

      return true;
    } catch (e) {
      return false;
    }
  };

  const onChangeGreetingMessages = async () => {
    try {
      const { data } = await requestClient.put("page/greeting_messages", {
        greeting_messages: greetingMessages,
      });
      appContext.showWarning(data.message, "success");
      revalidatePath(slug);

      return true;
    } catch (e) {
      return false;
    }
  };

  const onChangeLinkContact = async () => {
    try {
      const { data } = await requestClient.put("page/link-contact", {
        link_contact: linkContact,
      });
      appContext.showWarning(data.message, "success");
      revalidatePath(slug);

      return true;
    } catch (e) {
      return false;
    }
  };

  const onChangeLinkEmail = async () => {
    try {
      const { data } = await requestClient.put("page/link-email", {
        link_email: linkEmail,
      });
      appContext.showWarning(data.message, "success");
      revalidatePath(slug);

      return true;
    } catch (e) {
      return false;
    }
  };

  const onChangePictureRatio = async () => {
    try {
      const { data } = await requestClient.put("page/picture-ratio", {
        picture_ratio: projectPictureRatio,
      });
      appContext.showWarning(data.message, "success");
      revalidatePath(slug);

      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <section id="profile" style={ProfileSectionStyle.profile}>
      <article className="flex-1">
        <h2>Page Configurations</h2>
        <div className="input-group">
          <label htmlFor="slug">Page Status</label>
          <div className="container-horizontal">
            <Switch
              id={"switch-page-status"}
              defaultValue={pageStatus}
              onChanged={onChangePageStatus}
            />
          </div>
        </div>
        <div className="input-group mb-4">
          <label htmlFor="slug">Page Slug</label>
          <div className="container-horizontal">
            <input
              className="outline-dark"
              type="text"
              placeholder="Page Slug"
              value={slug}
              onChange={({ target }) => setSlug(target.value)}
            />
            <button className="primary-dark" onClick={onChangeSlug}>
              <FaExchangeAlt />
            </button>
          </div>
        </div>

        <div className="input-group mb-4">
          <label htmlFor="slug">Avatar</label>
          <div className="container-horizontal">
            <input
              className="outline-dark"
              type="text"
              placeholder="Avatar URL"
              value={avatar}
              onChange={({ target }) => setAvatar(target.value)}
            />
            <button className="primary-dark" onClick={onChangeAvatar}>
              <FaExchangeAlt />
            </button>
          </div>
        </div>
        <div className="input-group mb-4">
          <label htmlFor="slug">Page Name</label>
          <div className="container-horizontal">
            <input
              className="outline-dark"
              type="text"
              placeholder="Page Name"
              value={pageName}
              onChange={({ target }) => setPageName(target.value)}
            />
            <button className="primary-dark" onClick={onChangePageName}>
              <FaExchangeAlt />
            </button>
          </div>
        </div>
        <div className="input-group mb-4">
          <label htmlFor="slug">Position Name</label>
          <div className="container-horizontal">
            <input
              className="outline-dark"
              type="text"
              placeholder="Position Name"
              value={positionName}
              onChange={({ target }) => setPositionName(target.value)}
            />
            <button className="primary-dark" onClick={onChangePositionName}>
              <FaExchangeAlt />
            </button>
          </div>
        </div>
        <div className="input-group mb-4">
          <label htmlFor="greeting-messages">Greeting Message</label>
          <div className="container-horizontal">
            <textarea
              className="outline-dark"
              defaultValue={greetingMessages}
              onChange={(e) => setGreetingMessages(e.target.value)}
            />
            <button
              className="primary-dark mt-auto"
              onClick={onChangeGreetingMessages}
            >
              <FaExchangeAlt />
            </button>
          </div>
        </div>

        <div className="input-group mb-4">
          <label htmlFor="greeting-messages">Link Contact</label>
          <div className="container-horizontal">
            <input
              className="outline-dark"
              type="text"
              placeholder="Link Contact"
              value={linkContact}
              onChange={({ target }) => setLinkContact(target.value)}
            />
            <button
              className="primary-dark mt-auto"
              onClick={onChangeLinkContact}
            >
              <FaExchangeAlt />
            </button>
          </div>
        </div>

        <div className="input-group mb-4">
          <label htmlFor="greeting-messages">Link Email</label>
          <div className="container-horizontal">
            <input
              className="outline-dark"
              type="text"
              placeholder="Link Email"
              value={linkEmail}
              onChange={({ target }) => setLinkEmail(target.value)}
            />
            <button
              className="primary-dark mt-auto"
              onClick={onChangeLinkEmail}
            >
              <FaExchangeAlt />
            </button>
          </div>
        </div>

        <div className="input-group">
          <label htmlFor="greeting-messages">
            Project Picture Width To Height Ratio
          </label>
          <div className="container-horizontal">
            <input
              className="outline-dark"
              type="number"
              placeholder="Picture Ratio"
              value={projectPictureRatio}
              onChange={({ target }) => setProjectPictureRatio(target.value)}
            />
            <button
              className="primary-dark mt-auto"
              onClick={onChangePictureRatio}
            >
              <FaExchangeAlt />
            </button>
          </div>
        </div>
      </article>
      <div className="flex flex-col flex-1">
        <article>
          <h2>Today Statistic</h2>
          <div className="table-statistic">
            <table className="w-full table-auto border-separate border-spacing-4 border border-slate-500 ">
              <thead>
                <tr>
                  <th className="border border-slate-600 bg-slate-600">
                    Page Name
                  </th>
                  <th className="border border-slate-600 bg-slate-600">
                    Page Type
                  </th>
                  <th className="border border-slate-600 bg-slate-600">
                    Unique Visitor
                  </th>
                  <th className="border border-slate-600 bg-slate-600">
                    Total Visit
                  </th>
                </tr>
              </thead>
              <tbody>
                {statistic.map((stat, idx) => (
                  <tr key={idx}>
                    {/* <td className="border border-slate-700">{stat.ip}</td> */}
                    <td className="border border-slate-700">
                      <a href={stat.url} target="_blank">
                        {stat.page_name}
                      </a>
                    </td>
                    <td className="border border-slate-700 capitalize">
                      {stat.type}
                    </td>
                    <td className="border border-slate-700">
                      {stat.unique_visitor}
                    </td>
                    <td className="border border-slate-700">
                      {stat.total_visit}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </article>

        <article>
          <div className="flex gap-4">
            <h2>Last Activity</h2>
            <button
              className="circle-button primary-dark"
              onClick={getActivityData}
            >
              <BiRefresh />
            </button>
          </div>
          <div className="container-activity">
            {activities.map((activity) => (
              <div
                className="activity bg-slate-600 border-slate-600"
                key={activity.id}
              >
                <span className="ip">
                  Guest with ip {activity.ip} just visited {"  "}
                  <a className="capitalize" href={activity.url}>
                    {activity.page_name}
                  </a>
                </span>
              </div>
            ))}
          </div>
        </article>
      </div>
    </section>
  );
};

export default ProfileSection;
