import requestClient from "../../../request-client-api/RequestClient";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  projects: [],
  experienceOption: [],
};

const slicer = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    setProjects(state, actions) {
      state.projects = actions.payload.projects;
      state.experienceOption = actions.payload.experiences;
    },
  },
});

export const projectActionCreators = {
  fetchData: () => async (dispatch) => {
    requestClient
      .get(`/projects`)
      .then(({ data }) => {
        dispatch(projectActions.setProjects({ projects: data.data.projects, experiences: data.data.experiences }));
    });
  },
  reorderingProject: (newData, appContext) => {
    return async (dispatch) => {
        return requestClient
        .post(`/projects/reorder`, { projects: newData })
        .then(({ data }) => {
          appContext.showWarning(data.message, "success");
          dispatch(projectActionCreators.fetchData());
        })
        .catch((e) => console.log(e.response));
    }
  }
};

export const projectActions = slicer.actions;
export default slicer.reducer;
