import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./views/pages/login/Login";
import AppContextProvider from "./external/store/AppContext";
import LoadingBar from "./views/components/loading-bar/LoadingBar";
import AlertWarning from "./views/components/alert-warning/AlertWarning";
import Admin from "./views/pages/admin/Admin";
import Projects from "./views/components/admin/projects/Projects";
import ProfileSection from "./views/components/admin/profile-section/ProfileSection";
import ExperienceSection from "./views/components/admin/experience-section/ExperienceSection";
import { Provider } from "react-redux";

import ReduxStore from './external/store/redux/index';
import Certificates from "./views/components/admin/certificates/certificates";
import LoginGoogle from "./views/pages/login/LoginGoogle";
import UserPages from "./views/components/admin/user/UserPages";

function App() {
  return (
    <Provider store={ReduxStore}>
      <AppContextProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login/google/callback" element={<LoginGoogle />} />
          <Route path="/" element={<Admin />}>
            <Route path="/projects" element={<Projects />} />
            <Route path="/" element={<ProfileSection />} />
            <Route path="experiences" element={<ExperienceSection />} />
            <Route path="certificates" element={<Certificates />} />
            <Route path="user" element={<UserPages />} />
          </Route>
        </Routes>
        <LoadingBar />
        <AlertWarning />
      </AppContextProvider>
    </Provider>
  );
}

export default App;
