import { useContext, useEffect, useRef, useState } from "react";
import {
  addPreventDefault,
  addPreventDefaultOnSomeKey,
  revalidatePath,
} from "../../../../../external/global-function/global-function";
import requestClient from "../../../../../external/request-client-api/RequestClient";
import { AppContext } from "../../../../../external/store/AppContext";
import SelectMultiple from "../../../select-multiple/SelectMultiple";
import FormEditProjectStyle from "./FormEditProject.css";

const FormEditProject = ({
  project,
  closePopUpAction,
  onEditDone,
  experiences,
}) => {
  const [tagInput, setTagInput] = useState("");
  const [listTagForm, addTagToForm] = useState([]);
  const [listPreview, setListPreview] = useState([]);
  const [previewURL, setPreviewURL] = useState("");
  const [previewCaption, setPreviewCaption] = useState("");
  const [listExperience, setListExperience] = useState(
    (project.experiences ?? []).map(e => e.id)
  );

  const onAddPreview = () => {
    setListPreview([
      ...listPreview,
      { caption: previewCaption, url: previewURL },
    ]);
  };

  const formRef = useRef();
  const appContext = useContext(AppContext);

  useEffect(() => {
    const [name, url, , cover, category, date, shared, , , , description, github_link, kaggle_link, figma_link] =
      formRef.current;

    console.log(description)

    name.value = project.name;
    url.value = project.url;
    cover.value = project.cover;
    description.value = project.description;
    category.value = project.category;
    date.value = project.date;
    shared.value = project.is_shared ? 'YES' : 'NO'
    github_link.value = project.github_link;
    kaggle_link.value = project.kaggle_link;
    figma_link.value = project.figma_link;

    addTagToForm(project.tags);

    setListPreview(project.preview);

    console.log(project);
  }, [project]);

  const addTagProjects = (e) => {
    e.preventDefault();
    if (e.code == "Tab" || e.code == "Enter") {
      setTagInput("");
      addTagToForm([...listTagForm, tagInput]);
    }
  };

  const onSubmitProject = (e) => {
    e.preventDefault();

    const [
      { value: name },
      { value: url },
      ,
      { value: cover },
      { value: category },
      { value: date },
      { value: shared },
      ,
      ,
      ,
      { value: description },
      { value: github_link },
      { value: kaggle_link },
      { value: figma_link },
    ] = formRef.current;

    const tags = listTagForm.join(",");

    const preview = JSON.stringify(listPreview);

    appContext.loadLoadingBar();
    requestClient
      .put("/projects", {
        project_id: project.id,
        name,
        url,
        cover,
        preview,
        description,
        tags,
        category,
        date,
        is_shared: shared.toLowerCase() === "yes",
        experiences: listExperience,
        github_link,
        kaggle_link,
        figma_link
      })
      .then(({ data }) => {
        appContext.showWarning(data.message, "success");
        formRef.current.reset();
        addTagToForm([]);
        revalidatePath(appContext.user.slug);
      })
      .catch((e) => {
        console.log(e.response);
      })
      .finally(() => {
        appContext.closeLoadingBar();
        onEditDone();
      });
  };

  const removeTags = (tag) => {
    addTagToForm(listTagForm.filter((oldTag) => oldTag != tag));
  };

  const removePreview = (preview) => {
    setListPreview(listPreview.filter((oldPreview) => oldPreview != preview));
  };

  return (
    <form
      onSubmit={onSubmitProject}
      style={FormEditProjectStyle.form}
      ref={formRef}
      className="edit-project"
    >
      <div className="container-horizontal">
        <div className="input-group">
          <label htmlFor="name">Project Name*</label>
          <input
            className="outlined"
            type="text"
            placeholder="Project Name"
            name="name"
          />
        </div>

        <div className="input-group">
          <label htmlFor="url">Project URL</label>
          <input className="outlined" type="text" placeholder="Project URL" />
        </div>
      </div>

      <div className="container-horizontal">
        <div className="input-group">
          <label htmlFor="tags">Relationship with Experience</label>
          <SelectMultiple
            value={experiences}
            className="outlined"
            placeholder={"Select Experience"}
            defaultValue={listExperience}
            onChangeValue={(val) => {
              setListExperience(val.map((v) => v.id));
            }}
          />
        </div>
      </div>

      <div className="container-horizontal">
        <div className="input-group">
          <label htmlFor="cover-url">Image Cover URL*</label>
          <input
            className="outlined"
            type="text"
            placeholder="Image Cover URL"
          />
        </div>
      </div>

      <div className="container-horizontal">
        <div className="input-group">
          <label htmlFor="cover-url">Kategori</label>
          <input className="outlined" type="text" placeholder="Kategori" />
        </div>
      </div>

      <div className="container-horizontal">
        <div className="input-group">
          <label htmlFor="cover-url">Date</label>
          <input className="outlined" type="text" placeholder="Date" />
        </div>
      </div>

      <div className="container-horizontal">
        <div className="input-group">
          <label htmlFor="cover-url">Shared Project (YES/NO) ?</label>
          <input
            className="outlined"
            type="text"
            placeholder="YES / NO"
            required
            pattern="^(?:Yes|No|YES|NO|yes|no)$"
          />
        </div>
      </div>

      <div className="container-horizontal">
        <div className="input-group !mb-0">
          <label htmlFor="cover-url">Image Preview</label>
          <input
            className="outlined"
            type="text"
            placeholder="Image Cover URL"
            onChange={(e) => setPreviewURL(e.target.value)}
          />
        </div>

        <div className="input-group !mb-0">
          <label htmlFor="preview-url">Image Caption</label>
          <input
            className="outlined"
            type="text"
            placeholder="Image Caption"
            onChange={(e) => setPreviewCaption(e.target.value)}
          />
        </div>

        <button
          type="button"
          className="primary-dark mt-8"
          onClick={onAddPreview}
        >
          Add
        </button>
      </div>

      <div className="container-tags">
        {listPreview.map((preview, idx) => (
          <span
            className="tag"
            key={idx}
            onDoubleClick={() => removePreview(preview)}
          >
            {preview.caption}
          </span>
        ))}
      </div>

      <div className="container-full">
        <div className="input-group">
          <label htmlFor="description">Project Description*</label>
          <textarea className="outlined" placeholder="Project Description" />
        </div>
      </div>

      <div className="container-horizontal">
        <div className="input-group">
          <label htmlFor="cover-url">Link Github</label>
          <input className="outlined" type="text" placeholder="Github URL" />
        </div>
      </div>

      <div className="container-horizontal">
        <div className="input-group">
          <label htmlFor="cover-url">Link Kaggle</label>
          <input className="outlined" type="text" placeholder="Kaggle URL" />
        </div>
      </div>

      <div className="container-horizontal">
        <div className="input-group">
          <label htmlFor="cover-url">Link Figma</label>
          <input className="outlined" type="text" placeholder="Figma URL" />
        </div>
      </div>

      <div className="container-horizontal">
        <div className="input-group">
          <label htmlFor="tags">Project Tags*</label>
          <input
            className="underlined"
            type="text"
            placeholder="Press Tab or Enter after typing to add new Tags"
            onChange={(e) => setTagInput(e.target.value)}
            onKeyUpCapture={addTagProjects}
            onKeyDownCapture={(e) =>
              addPreventDefaultOnSomeKey(e, ["Tab", "Enter"])
            }
            value={tagInput}
          />
          <div className="container-tags">
            {listTagForm.map((tag, idx) => (
              <span
                className="tag"
                key={idx}
                onDoubleClick={() => removeTags(tag)}
              >
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>

      <div className="actions flex w-full">
        <button className="text-button flex-1" onClick={closePopUpAction}>
          Cancel
        </button>
        <button
          className="primary-dark flex-1"
          type="submit"
          id="btn-add-project"
        >
          Edit Project
        </button>
      </div>
    </form>
  );
};

export default FormEditProject;
