import { Fragment, useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addPreventDefault,
  addPreventDefaultOnSomeKey,
  revalidatePath,
} from "../../../../../external/global-function/global-function";
import requestClient from "../../../../../external/request-client-api/RequestClient";
import { AppContext } from "../../../../../external/store/AppContext";
import { projectActionCreators, projectActions } from "../../../../../external/store/redux/slice/project-slice";
import SelectMultiple from "../../../select-multiple/SelectMultiple";
import FormProjectStyle from "./FormProject.css";

const FormProject = ({ experiences }) => {
  const [tagInput, setTagInput] = useState("");
  const [listTagForm, addTagToForm] = useState([]);
  const [listPreview, setListPreview] = useState([]);
  const [previewURL, setPreviewURL] = useState("");
  const [previewCaption, setPreviewCaption] = useState("");
  const [listExperience, setListExperience] = useState([]);
  const dispatch = useDispatch();

  const [isShowed, setIsShowed] = useState(false);

  const formRef = useRef();
  const appContext = useContext(AppContext);

  const addTagProjects = (e) => {
    e.preventDefault();
    if (e.code == "Tab" || e.code == "Enter") {
      setTagInput("");
      addTagToForm([...listTagForm, tagInput]);
    }
  };

  const onSubmitProject = (e) => {
    e.preventDefault();

    const [
      { value: name },
      { value: url },
      ,
      { value: cover },
      { value: category },
      { value: date },
      { value: shared },
      ,
      ,
      ,
      { value: description },
      { value: github_link },
      { value: kaggle_link },
      { value: figma_link },

    ] = formRef.current;

    const tags = listTagForm.join(",");
    let preview = JSON.stringify(listPreview);

    appContext.loadLoadingBar();
    requestClient
      .post("/projects", {
        name,
        url,
        cover,
        preview,
        description,
        tags,
        category,
        date,
        is_shared: shared.toLowerCase() === 'yes',
        experiences: listExperience,
        github_link: github_link,
        kaggle_link: kaggle_link,
        figma_link: figma_link,
      })
      .then(({ data }) => {
        dispatch(projectActionCreators.fetchData());
        appContext.showWarning(data.message, "success");
        formRef.current.reset();
        addTagToForm([]);
        setListPreview([]);
        revalidatePath(appContext.user.slug);
      })
      .catch((e) => {
        console.log(e.response);
      })
      .finally(appContext.closeLoadingBar);
  };

  const removeTags = (tag) => {
    addTagToForm(listTagForm.filter((oldTag) => oldTag != tag));
  };

  const onAddPreview = () => {
    setListPreview([
      ...listPreview,
      { caption: previewCaption, url: previewURL },
    ]);
  };

  return (
    <Fragment>
      <button
        id="open-add-project"
        className="circle-button primary-dark text-2xl font-bold !w-16 !h-16 !rounded-full"
        onClick={() => setIsShowed(!isShowed)}
      >
        {!isShowed ? '+' : 'x'}
      </button>
      {isShowed && (
        <form
          onSubmit={onSubmitProject}
          style={FormProjectStyle.form}
          ref={formRef}
          id="form-add-project"
        >
          <div className="container-horizontal">
            <div className="input-group">
              <label htmlFor="name">Project Name*</label>
              <input
                className="outline-dark"
                type="text"
                placeholder="Project Name"
                name="name"
              />
            </div>

            <div className="input-group">
              <label htmlFor="url">Project URL</label>
              <input
                className="outline-dark"
                type="text"
                placeholder="Project URL"
              />
            </div>
          </div>

          <div className="container-horizontal">
            <div className="input-group">
              <label htmlFor="tags">Relationship with Experience</label>
              <SelectMultiple
                value={experiences}
                className="outline-dark"
                placeholder={"Select Experience"}
                onChangeValue={(val) => setListExperience(val.map((v) => v.id))}
              />
            </div>
          </div>

          <div className="container-horizontal">
            <div className="input-group">
              <label htmlFor="cover-url">Image Cover URL*</label>
              <input
                className="outline-dark"
                type="text"
                placeholder="Image Cover URL"
              />
            </div>
          </div>

          <div className="container-horizontal">
            <div className="input-group">
              <label htmlFor="cover-url">Kategori*</label>
              <input
                className="outline-dark"
                type="text"
                placeholder="Kategori"
                required
              />
            </div>
          </div>

          <div className="container-horizontal">
            <div className="input-group">
              <label htmlFor="cover-url">Date*</label>
              <input
                className="outline-dark"
                type="text"
                placeholder="Date"
                required
              />
            </div>
          </div>

          <div className="container-horizontal">
            <div className="input-group">
              <label htmlFor="cover-url">Shared Project (YES / NO) ?</label>
              <input
                className="outline-dark"
                type="text"
                placeholder="YES / NO"
                required
                pattern="^(?:Yes|No|YES|NO|yes|no)$"
              />
            </div>
          </div>

          <div className="container-horizontal">
            <div className="input-group !mb-0">
              <label htmlFor="cover-url">Image Preview</label>
              <input
                className="outline-dark"
                type="text"
                placeholder="Image Cover URL"
                onChange={(e) => setPreviewURL(e.target.value)}
              />
            </div>

            <div className="input-group !mb-0">
              <label htmlFor="preview-url">Image Caption</label>
              <input
                className="outline-dark"
                type="text"
                placeholder="Image Caption"
                onChange={(e) => setPreviewCaption(e.target.value)}
              />
            </div>

            <button
              type="button"
              className="primary-dark mt-8"
              onClick={onAddPreview}
            >
              Add
            </button>
          </div>

          <div className="container-tags">
            {listPreview.map((preview, idx) => (
              <span
                className="tag"
                key={idx}
                onDoubleClick={() => removeTags(preview)}
              >
                {preview.caption}
              </span>
            ))}
          </div>

          <div className="container-full">
            <div className="input-group">
              <label htmlFor="description">Project Description*</label>
              <textarea
                className="outline-dark"
                placeholder="Project Description"
              />
            </div>
          </div>

          <div className="container-full">
            <div className="input-group">
              <label htmlFor="description">Link Github</label>
              <input
                className="outline-dark"
                type="text"
                placeholder="Link Github"
              />
            </div>
          </div>

          <div className="container-full">
            <div className="input-group">
              <label htmlFor="description">Link Kaggle</label>
              <input
                className="outline-dark"
                type="text"
                placeholder="Link Kaggle"
              />
            </div>
          </div>

          <div className="container-full">
            <div className="input-group">
              <label htmlFor="description">Link Figma</label>
              <input
                className="outline-dark"
                type="text"
                placeholder="Link Figma"
              />
            </div>
          </div>

          <div className="container-horizontal">
            <div className="input-group">
              <label htmlFor="tags">Project Tags*</label>
              <input
                className="underlined-dark"
                type="text"
                placeholder="Press Tab or Enter after typing to add new Tags"
                onChange={(e) => setTagInput(e.target.value)}
                onKeyUpCapture={addTagProjects}
                onKeyDownCapture={(e) =>
                  addPreventDefaultOnSomeKey(e, ["Tab", "Enter"])
                }
                value={tagInput}
              />
              <div className="container-tags">
                {listTagForm.map((tag, idx) => (
                  <span
                    className="tag"
                    key={idx}
                    onDoubleClick={() => removeTags(tag)}
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <button className="primary-dark" type="submit" id="btn-add-project">
            Add Project
          </button>
        </form>
      )}
    </Fragment>
  );
};

export default FormProject;
