import { useEffect, useState } from "react";
import requestClient from "../../../../external/request-client-api/RequestClient";
import "./ExperienceSection.css";
import FormAddExperience from "./form-add-experience/FormAddExperience";
import ListExperience from "./list-experience/ListExperience";

const ExperienceSection = () => {
  const [initValue, setInitValue] = useState(null)
  return (
    <section id="experience-section">
      <article>
        <h2>MY EXPERIENCE</h2>
        <ListExperience  onEditExperience={(exp) => {
            setInitValue(exp)
        }}/>
      </article>
      <article>
        <h2>ADD NEW EXPERIENCE</h2>
        <FormAddExperience initValue={initValue} onCompleteEdit={() => setInitValue(null)}/>
      </article>
    </section>
  );
};

export default ExperienceSection;
