const Overlay = ({children, className, onMouseMove, onMouseUp, onTouchEnd, onTouchMove, onMouseOut}) => {
    return <section className={`overlay ${className ?? ''}`} style={{
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        background: "rgba(0,0,0,0.4)",
        zIndex: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }}
        onMouseMove = {onMouseMove}
        onMouseUp = {onMouseUp}
        onTouchMove = {onTouchMove}
        onTouchEnd = {onTouchEnd}
        onMouseOut = {onMouseOut}
    >
        {children}
    </section>
}

export default Overlay;