import { useContext } from "react";
import { AppContext } from "../../../external/store/AppContext";

import AlertWarningStyle from "./AlertWarning.css";

const AlertWarning = () => {
  const appContext = useContext(AppContext);

  return (
    <div className="container-alert" style={AlertWarningStyle.containerAlert}>
      {appContext.warning.isWarningShowed ? (
        <div className={`alert ${appContext.warning.type}`}>
          {appContext.warning.message}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AlertWarning;
