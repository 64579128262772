import requestClient from "../../../../external/request-client-api/RequestClient";
import FormProject from "./form-project/FormProject";
import Overlay from "../overlay/Overlay";
import ProjectsStyle from "./Projects.css";
import ListProject from "./list-project/ListProject";
import { useEffect, useState } from "react";
import ReorderModal from "../reorder-modal/ReorderModal";

import { BiSortDown } from "react-icons/bi";
import { useContext } from "react";
import { AppContext } from "../../../../external/store/AppContext";
import { useDispatch, useSelector, useStore } from "react-redux";
import { projectActionCreators, projectActions } from "../../../../external/store/redux/slice/project-slice";
import useFetchData from "../../../../external/hooks/use-fetch-data";
import { revalidatePath } from "../../../../external/global-function/global-function";

const Projects = () => {
  // const [projects, setProjects] = useState([]);
  const projects = useSelector(state => state.project.projects);
  const [experiences, reloadExperiences] = useFetchData([], 'projects', 'experiences');
  const [isReorderDialogOpen, setIsReorderDialogOpen] = useState(false);
  const appContext = useContext(AppContext);
  const dispatch = useDispatch();

  const getData = () => {
    dispatch(projectActionCreators.fetchData());
  };

  console.log('lesini');

  useEffect(() => {
    getData();
  }, []);


  const onReorderingData = (newData, newIndex) => {
    console.log(newIndex);
    dispatch(projectActionCreators.reorderingProject(newData, appContext)).then(() => {
      setIsReorderDialogOpen(false);
      revalidatePath(appContext.user.slug);
    })
  };

  return (
    <section id="projects" style={ProjectsStyle.section}>
      <article>
        <div className="flex justify-between">
          <h2>MY PROJECT</h2>
          <button
            className="secondary-dark circle-button"
            onClick={() => setIsReorderDialogOpen(true)}
          >
            <BiSortDown />
          </button>
        </div>
        <ListProject projects={projects} experiences={experiences} getData={getData} />
        {isReorderDialogOpen ? (
          <ReorderModal
            data={projects}
            onSubmitReorder={onReorderingData}
            onCancelReorder={() => setIsReorderDialogOpen(false)}
          />
        ) : (
          ""
        )}
      </article>
      <FormProject experiences={experiences}/>     
    </section>
  );
};

export default Projects;
