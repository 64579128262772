import { useEffect, useState } from "react";
import requestClient from "../request-client-api/RequestClient";

const useFetchData = (initData, url, special) => {
  const [data, setData] = useState(initData);

  useEffect(() => {
    reloadData();
  }, []);

  const reloadData = () => {
    requestClient
      .get(`/${url}`)
      .then(({ data }) => {
        if(special == null){
          setData(data.data);
        }else{
          setData(data.data[special]);
        }
      });
  }

  return [data, reloadData];
};

export default useFetchData;
