import { useContext, useEffect, useState } from "react";
import requestClient from "../../../../../external/request-client-api/RequestClient";
import { MdEdit, MdDelete } from "react-icons/md";

import "./ListExperience.css";
import { useDispatch, useSelector } from "react-redux";
import {
  experienceAction,
  experienceActionCreators,
} from "../../../../../external/store/redux/slice/experience-slice";
import { AppContext } from "../../../../../external/store/AppContext";
import { revalidatePath } from "../../../../../external/global-function/global-function";

const ListExperience = ({ onEditExperience }) => {
  // const [experiences, setExperiences] = useState([]);
  const experiences = useSelector((state) => state.experience.experiences);
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);

  useEffect(() => {
    dispatch(experienceActionCreators.fetchData());
  }, []);

  return (
    <div className="list-experience">
      {experiences.map((exp) => (
        <div key={exp.id} className="experience">
          <div className="marker"></div>
          <div className="detail flex flex-col w-full">
            <h3 className="title">{exp.title}</h3>
            <span className="info">
              {exp.company_name} - {exp.type}
            </span>
            <span className="date">
              {exp.start_date} - {exp.end_date}
            </span>
            <div className="flex justify-between items-end relative mt-6">
              <div className="line"></div>
              <div className="actions flex gap-4">
                <button
                  className="circle-button primary-dark"
                  onClick={() => onEditExperience(exp)}
                >
                  <MdEdit />
                </button>

                <button className="circle-button error">
                  <MdDelete
                    onClick={() => {
                      requestClient
                        .delete(`/experiences?id=${exp.id}`)
                        .then(({data}) => {
                          appContext.showWarning(data.message, "success");
                          revalidatePath(appContext.user.slug);
                          dispatch(experienceActionCreators.fetchData());
                        });
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListExperience;
