import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import requestClient from "../../../external/request-client-api/RequestClient";
import { AppContext } from "../../../external/store/AppContext";
import LoginStyle from "./Login.module.css";
import { FaGoogle } from "react-icons/fa";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [googleUrl, setGoogleUrl] = useState("");
  
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  // useEffect(() => {}, {

  // })

  const loginWithGoogle = async () => {
    appContext.loadLoadingBar();
    var result = await requestClient.get('/login-google');

    window.open(result.data, "_self");
    navigate(result.data)
    appContext.closeLoadingBar();
  }

  const onLoginClicked = async (e) => {
    e.preventDefault();
    appContext.loadLoadingBar();

    requestClient.post("/login", {
      email,
      password,
    })
      .then((result) => {
        localStorage.setItem("token", result.data.token);
        appContext.showWarning(result.data.message,"success");
        navigate('/');
        
      })
      .finally(() => {
        appContext.closeLoadingBar();
      });
  };

  return (
    <main className={LoginStyle['login-page']}>
      <div className={LoginStyle.card}>
        <img
          src="https://general.itsmysite.my.id/images/Kiu7ubrLSQiou2EXLoUPSLbAxpJLEmx9p2cyEgDr.svg"
          alt="illustration login"
          height="200px"
        />
        <form onSubmit={onLoginClicked}>
          <h2>Portfolio Login</h2>
          <p>Enter your account details to get sign in to your account</p>
          <input
            className="underlined"
            type="email"
            name="email"
            id="input-email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <input
            className="underlined"
            type="password"
            name="password"
            id="input-password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <div className="px-4 flex flex-col gap-4">
            <button className="primary !w-full" type="submit">
              Login
            </button>
            <p>or</p>
            <button className="!w-full flex items-center gap-4 justify-center text-sm !mt-0" type="button" onClick={loginWithGoogle}>
              <FaGoogle/> Login with Google
            </button>
          </div>
        </form>
        <span className="link">
          Don't have an account?{" "}
          <a target="_blank" href="https://www.linkedin.com/in/wendyyy/">
            Request Now
          </a>
        </span>
      </div>
    </main>
  );
};

export default Login;
