import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Overlay from "../overlay/Overlay";
import "./ReorderModal.css";

const ReorderModal = ({ data, onSubmitReorder, onCancelReorder }) => {
  const [isActive, setIsActive] = useState(false);
  const [idxActive, setIdxActive] = useState(-1);
  const [startCoordinat, setStartCoordinat] = useState(-1);
  const cardRef = useRef();
  const [dataTemp, setDataTemp] = useState([]);
  const [defaultIndex, setDefaultIndex] = useState(-1);
  const [newIndex, setNewIndex] = useState([]);
  const selectedCardRef = useRef();

  useEffect(() => {
    setDataTemp(data);
    setDefaultIndex([...Array(data.length).keys()])
  }, [data]);

  useEffect(() => {
    let allItem = document.querySelectorAll(".item");
    allItem.forEach((item) => {
      if (isActive) {
        item.classList.remove("no-transition");
      } else {
        item.classList.add("no-transition");
      }
      item.style.top = 0;
    });
  }, [isActive]);

  const onMouseMove = (e) => {
    if (isActive) {
      let moveCoordinat =
        (e.clientY ?? e.changedTouches[0].clientY) - startCoordinat;

      if (moveCoordinat > (dataTemp.length - (idxActive + 1)) * 76) {
        return;
      }

      if (moveCoordinat < (0 - idxActive) * 76) {
        return;
      }

      document.querySelector(".item.active").style.top = `${moveCoordinat}px`;
      onMoveOtherItem(e.clientY);
    }
  };

  const onMoveOtherItem = () => {
    let allItem = document.querySelectorAll(".item");

    let activeCoordinatBottom = document
      .querySelector(".item.active")
      .getBoundingClientRect().bottom;
    let activeCoordinatTop = document
      .querySelector(".item.active")
      .getBoundingClientRect().top;

    allItem.forEach((item, idx) => {
      if (!item.classList.contains("active")) {
        let itemCoordinatTop = item.getBoundingClientRect().top;
        let itemCoordinatBottom = item.getBoundingClientRect().bottom;

        if (!item.getAttribute("available")) {
          if (
            activeCoordinatTop < itemCoordinatTop &&
            activeCoordinatBottom > itemCoordinatTop + 30
          ) {
            if (idxActive < idx) {
              item.style.top = "-76px";
              defaultIndex[idx] = idx - 1;
              defaultIndex[idxActive] = idx;
            } else {
              item.style.top = "0px";
              defaultIndex[idx] = idx;
              defaultIndex[idxActive] = idx + 1;
            }
            
            setDefaultIndex(defaultIndex);
            // console.log(defaultIndex[idx], defaultIndex[idxActive]);
          }
        }

        if (itemCoordinatBottom - 5 < activeCoordinatTop) {
          item.setAttribute("available", true);
        }

        if (itemCoordinatTop + 5 > activeCoordinatBottom) {
          item.removeAttribute("available");
        }

        if (item.getAttribute("available")) {
          if (activeCoordinatTop < itemCoordinatBottom - 30) {
            if (idxActive < idx) {
              item.style.top = "0px";
              defaultIndex[idx] = idx ;
              defaultIndex[idxActive] = idx - 1;
            } else {
              item.style.top = "76px";
              defaultIndex[idx] = idx + 1;
              defaultIndex[idxActive] = idx;
            }
            // if(defaultIndex[idxActive] < 0) defaultIndex[idxActive] = 0;
            setDefaultIndex(defaultIndex);
            // console.log(defaultIndex[idx], defaultIndex[idxActive]);

          }
        }
      }
    });
  };

  const onMouseHold = (e, idx) => {
    // console.log(defaultIndex);
    setStartCoordinat(
      +(e.clientY ?? e.changedTouches[0].clientY)
    );
    setDefaultIndex([...Array(data.length).keys()]);
    setIsActive(true);
    setIdxActive(idx);
  };

  const onMouseRelease = (e) => {
    if(isActive){
      setIsActive(false);
  
      // console.log(defaultIndex);
      let newData = [];
      defaultIndex.forEach((idx, oldIdx) => {
        newData[idx] = {...dataTemp[oldIdx]};
        newData[idx]["order"] = idx;
      });
      
      setNewIndex(defaultIndex);
      setDataTemp(newData);
    }
  };

  const onMouseOut = (e) => {
    if(e.clientX <= 0 || e.clientY <= 0 || e.clientX >= window.innerWidth || e.clientY >= window.innerHeight){
      onMouseRelease();
    }
  }

  return (
    <Overlay
      className={"reorder-modal"}
      onMouseMove={onMouseMove}
      onTouchMove={onMouseMove}
      onMouseUp={onMouseRelease}
      onTouchEnd={onMouseRelease}
      onMouseOut={onMouseOut}
    >
      <div className="card flex flex-col" ref={cardRef}>
        <h4>Reorder Project</h4>
        <div className="container-data mb-4">
          {dataTemp.map((item, idx) => (
            <div
              key={item.id}
              className={`item ${
                idxActive == idx && isActive ? "active" : ""
              } relative`}
              onMouseDown={(e) => onMouseHold(e, idx)}
              onMouseUp={onMouseRelease}
              onTouchStart={(e) => onMouseHold(e, idx)}
              draggable="false"
              ref={idxActive == idx && isActive ? selectedCardRef : null}
            >
              {item.name}
            </div>
          ))}
        </div>
        <div className="action flex">
          <button className="flex-1" onClick={onCancelReorder}>Cancel</button>
          <button className="primary flex-1" onClick={() => onSubmitReorder(dataTemp, newIndex)}>Submit</button>
        </div>
      </div>
    </Overlay>
  );
};

export default ReorderModal;
