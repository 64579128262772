import { useContext, useEffect, useState } from "react"
import requestClient from "../../../../external/request-client-api/RequestClient";
import { AppContext } from "../../../../external/store/AppContext"
import { revalidatePath } from "../../../../external/global-function/global-function";

export default function Certificates() {
    const client = requestClient;
    const appContext = useContext(AppContext);
    let formField = [
        {
            id: 'title',
            title: 'Title'
        },
        {
            id: 'organization',
            title: 'Organization'
        },
        {
            id: 'year',
            title: 'Year'
        },
        {
            id: 'credentials_link',
            title: 'Credentials_link'
        },
        {
            id: 'images',
            title: 'Image'
        },
        {
            id: 'skills',
            title: 'Skills'
        },
        {
            id: 'description',
            title: 'Description'
        },
    ]

    const fetchData = async () => {
        var response = await client.get(`/certificates/${appContext.user.slug}`)

        setListData(response.data.data ?? []);
    }

    const [formData, setFormData] = useState({});
    const [listData, setListData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const onChange = (event) => {
        const name = event.target.name;

        setFormData((old) => ({ ...old, [name]: event.target.value, }))
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        console.log(formData);

        appContext.loadLoadingBar();
        if (isEdit) {
            await client.put('/certificates', formData);
        } else {
            await client.post('/certificates', formData);
        }
        await fetchData();
        revalidatePath(appContext.user.slug);
        setFormData({});
        setIsEdit(false);
        appContext.closeLoadingBar();
    }

    const onDelete = async (event) => {
        event.preventDefault();

        appContext.loadLoadingBar();
        await client.delete(`/certificates/${formData.id}`);
        revalidatePath(appContext.user.slug);
        await fetchData();
        setFormData({});
        setIsEdit(false);
        appContext.closeLoadingBar();
    }

    const onEdit = (data) => {
        setIsEdit(true);
        setFormData(data);
    }

    useEffect(() => {
        console.log(appContext.user);


        fetchData();

    }, [])

    return <section className="flex">
        <form className="flex flex-col gap-4 items-end w-fit border-r pr-6 border-r-gray-600" onSubmit={onSubmit}>
            {formField.map((obj) => <div className="input-group" key={obj.id}>
                <label htmlFor="name">{obj.title}</label>
                <input
                    className="outline-dark"
                    type="text"
                    placeholder={obj.title}
                    name={obj.id}
                    onChange={onChange}
                    value={formData[obj.id] ?? ''}
                    required
                />
            </div>
            )}

            <div className="flex gap-2">
                {isEdit && <button
                    type="button"
                    className="error mt-8 w-fit"
                    onClick={onDelete}
                >Hapus</button>}

                <button
                    type="submit"
                    className="primary-dark mt-8 w-fit"
                >Simpan</button>

            </div>
        </form>

        <div className="flex-1 flex flex-col px-4 gap-5">
            {listData.map((cert) => <div className="flex gap-5 cursor-pointer" key={cert.id} onClick={() => onEdit(cert)}>
                <div className="w-1.5 h-1.5 bg-white rounded-full mt-2">

                </div>
                <div className="w-full">
                    <p className="mb-1">{cert.title}</p>
                    <div className="flex justify-between text-xs text-white/80 mb-1">
                        <p>{cert.organization}</p>
                        <p>{cert.year}</p>
                    </div>
                    <p className="text-xs">Skills: {cert.skills}</p>
                </div>
            </div>)}
        </div>

    </section>
}