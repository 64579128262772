import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import requestClient from "../../../external/request-client-api/RequestClient";
import { AppContext } from "../../../external/store/AppContext";
import LoginStyle from "./Login.module.css";
import { FaGoogle } from "react-icons/fa";

const LoginGoogle = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useSearchParams();

  const [status, setStatus] = useState("waiting");
  const [errorMessage, setErrorMessage] = useState('-')

  useEffect(() => {
    // console.log(searchParams[0].get('state'));
    const fetchData = async () => {
      try{
        var result = await requestClient.get(
          `/login-google/callback${location.search}`
        );
  
        setStatus('success');
        appContext.setUser(result.data);
        appContext.showWarning('Success', 'success')
        localStorage.setItem("token", result.data.token);
  
        setTimeout(() => {
          navigate('/')
        }, 3000);
      }catch(e){
        // if(errorMessage != '-') return;

        setStatus((old) => {

          if(old === 'success') return old;

          setTimeout(() => {
            navigate('/login')
          }, 5000);

          return 'error'
        });
        if(e.response.status == 401){
          setErrorMessage((oldest) => oldest != '-' ? oldest : 'Authenticating Failed, User is not allowed to enter the website. You will be redirect to login in 5 seconds.');
        }else{
          setErrorMessage((oldest) => oldest != '-' ? oldest : 'Authenticating Failed, Please Call Admin. You will be redirect to login in 5 seconds.');
        }

      }

    };

    fetchData();
  }, []);

  return (
    <main className={LoginStyle["login-page"]}>
      {status === "waiting" ? (
        <div className="text-white">
          Authenticating with Google, please wait...
        </div>
      ) : status === "success" ? (
        <div className="text-white">
          Authenticating Success, will be redirect in 3 seconds.
        </div>
      ) : (
        <div className="text-white">
          {errorMessage}
        </div>
      )}
    </main>
  );
};

export default LoginGoogle;
