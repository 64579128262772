import { useEffect, useState } from "react";
import "./SelectMultiple.css";

import { AiFillCaretDown } from "react-icons/ai";

const SelectMultiple = ({ value, className, placeholder, onChangeValue, defaultValue }) => {
  const [choosenValue, setChoosenValue] = useState(defaultValue ?? []);
  const [inputTitle, setInputTitle] = useState(placeholder);
  const [isShowed, setIsShowed] = useState(false);  

  useEffect(() => {
    if(defaultValue != null && defaultValue.length > 0){
      updateTitlePage(defaultValue.length);
    }
  }, [defaultValue]);

  const onChooseValue = (value) => {
    if (choosenValue.some(old => old.title == value.title)) {
      const newValue = choosenValue.filter((oldVal) => oldVal.title != value.title);
      setChoosenValue(newValue);
      updateTitlePage(newValue.length);
      if (onChangeValue != null) onChangeValue(newValue);
    } else {
      const newValue = [...choosenValue, value]
      setChoosenValue([...newValue]);
      updateTitlePage(newValue.length);
      console.log(choosenValue)
      if (onChangeValue != null) onChangeValue(newValue);
    }
  };

  const updateTitlePage = (len) => {
    if (len == 0) {
      setInputTitle(placeholder);
    } else {
      setInputTitle(`${len} Selected`);
    }
  };

  return (
    <div className="select-multiple flex flex-col ">
      <input
        type="text"
        className={className + " cursor-pointer"}
        readOnly={true}
        value={inputTitle}
        onClick={() => setIsShowed(!isShowed)}
      />
      <AiFillCaretDown className="absolute right-3 top-3"/>
      {isShowed && (
        <div className="container-value">
          {value.map((val, idx) => (
            <div
              key={idx}
              className={`value ${choosenValue.some(old => old.title == val.title) && "choosed"}`}
              onClick={() => onChooseValue(val)}
            >
              <span className="name">{val.title}</span>
              <div className="status"></div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectMultiple;
