import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import requestClient from "../../../external/request-client-api/RequestClient";
import { AppContext } from "../../../external/store/AppContext";
import Sidebar from "../../components/admin/sidebar/Sidebar";
import AdminStyle from "./Admin.css";

const Admin = () => {
  const token = localStorage.getItem("token");
  let [isAuthenticated, setAuthenticated] = useState(true);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (token === null) {
      setAuthenticated(false)
    } else {
      requestClient
        .get("/user")
        .then((result) => {
          appContext.setUser(result.data.name, result.data.id, result.data.page.slug);
        })
        .catch((_) => {
          localStorage.removeItem("token");
          setAuthenticated(false);
        });
    }
  }, []);

  return (
    isAuthenticated ? <main style={AdminStyle.main} className="admin-page">
      <Sidebar />
      <div className="container-section">
        {appContext.user.id === '' ? <div>Loading...</div> : <Outlet />}
      </div>
    </main> : <Navigate to="/login" />
  );
};

export default Admin;
