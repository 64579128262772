import experienceSlice from "./slice/experience-slice";
import projectSlice from "./slice/project-slice";

const { configureStore } = require("@reduxjs/toolkit");

const store = configureStore({
  reducer: {
    project: projectSlice,
    experience: experienceSlice
  },
});

export default store;
