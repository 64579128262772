import { useContext } from "react";
import { AppContext } from "../../../external/store/AppContext";
import LoadingBarStyle from "./LoadingBar.css";

const LoadingBar = ({internalState = null}) => {
  const appContext = useContext(AppContext);

  return (
    <div className="container-loading-bar">
      {(internalState != null ? internalState : appContext.isLoadingBarOn) ? (
        <div className="loading-bar" style={LoadingBarStyle.loadingBar}>
            <span className="title">It's Loading</span>
          <svg>
            <circle className="circle"></circle>
          </svg>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default LoadingBar;
