import { useContext, useEffect, useState } from "react";
import requestClient from "../../../../external/request-client-api/RequestClient";
import { AppContext } from "../../../../external/store/AppContext";

export default function UserPages() {
  const client = requestClient;
  const appContext = useContext(AppContext);
  let formField = [
    {
      id: "name",
      title: "Name",
    },
    {
      id: "email",
      title: "Allowed Email",
    },
    // {
    //     id: 'expired_at',
    //     title: 'Expired'
    // },
  ];

  const fetchData = async () => {
    try {
      var response = await client.get(`/user/allowed-accounts`);

      setListData(response.data.data ?? []);
    } catch {
      setListData([]);
    }
  };

  const [formData, setFormData] = useState({});
  const [listData, setListData] = useState([]);

  const onChange = (event) => {
    const name = event.target.name;

    setFormData((old) => ({ ...old, [name]: event.target.value }));
  };

  const onSubmit = async (event) => {
    try{
        event.preventDefault();
        console.log(formData);
    
        appContext.loadLoadingBar();
       
          await client.post("/user/allowed-accounts", formData);
        
        await fetchData();
        setFormData({});
    }catch(e){
        appContext.showWarning(JSON.stringify(e.response.data.errors), 'error');
    }
    appContext.closeLoadingBar();

  };

  useEffect(() => {
    console.log(appContext.user);

    fetchData();
  }, []);

  return (
    <section className="flex">
      <form
        className="flex flex-col gap-4 items-end w-fit border-r pr-6 border-r-gray-600"
        onSubmit={onSubmit}
      >
        {formField.map((obj) => (
          <div className="input-group" key={obj.id}>
            <label htmlFor="name">{obj.title}</label>
            <input
              className="outline-dark"
              type="text"
              placeholder={obj.title}
              name={obj.id}
              onChange={onChange}
              value={formData[obj.id] ?? ""}
              required
            />
          </div>
        ))}

        <div className="flex gap-2">
    
          <button type="submit" className="primary-dark mt-8 w-fit">
            Simpan
          </button>
        </div>
      </form>

      <div className="flex-1 flex flex-col px-4 gap-5">
        {listData.map((acc) => (
          <div
            className="flex gap-5 cursor-pointer"
            key={acc.id}
          >
            <div className="w-1.5 h-1.5 bg-white rounded-full mt-2"></div>
            <div className="w-full">
              <p className="mb-1">{acc.name}</p>
              <div className="flex justify-between text-xs text-white/80 mb-1">
                <p>{acc.email}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
