import axios from "axios";
import requestClient from "../request-client-api/RequestClient";

export const addPreventDefault = (e => {
    e.preventDefault();
});

export const addPreventDefaultOnSomeKey = ((e, listKeyCode) => {
    if(listKeyCode.includes(e.code)){
        e.preventDefault();
    }
})

export const revalidatePath = (path) => {
    axios.get(`https://whodevs.codes/api/revalidate?path=${path}`)
}