import { createSlice } from "@reduxjs/toolkit";
import requestClient from "../../../request-client-api/RequestClient";

const slicer = createSlice({
    name: "experience",
    initialState: {
        experiences: []
    },
    reducers: {
        setUpExperience(state, actions){
            state.experiences = actions.payload.experiences;
        }
    }
});

export const experienceActionCreators = {
    fetchData: () => {
        return (dispatch) => {
            requestClient
            .get("/experiences")
            .then(({ data }) => {
                dispatch(experienceAction.setUpExperience({experiences: data.data}));
            });
        }
    }
}

export const experienceAction = slicer.actions;
export default slicer.reducer;