import { useEffect, useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import { addPreventDefaultOnSomeKey, revalidatePath } from "../../../../../external/global-function/global-function";
import requestClient from "../../../../../external/request-client-api/RequestClient";
import { AppContext } from "../../../../../external/store/AppContext";
import { experienceActionCreators } from "../../../../../external/store/redux/slice/experience-slice";
import { useDispatch } from "react-redux";

const FormAddExperience = ({ initValue, onCompleteEdit }) => {
  const [listSkill, setListSkill] = useState([]);
  const [skillInput, setSkillInput] = useState("");
  const formRef = useRef();
  const appContext = useContext(AppContext);

  const onAddSkills = (event) => {
    if (event.code == "Tab" || event.code == "Enter") {
      setListSkill([...listSkill, skillInput]);
      setSkillInput("");
    }
  };

  useEffect(() => {
    if (initValue != null) {
      setListSkill(initValue.skills.split(','))
    }
  }, [initValue]);

  const removeSkill = (skill) => {
    setListSkill(listSkill.filter((oldSkill) => oldSkill != skill));
  };

  const dispatch = useDispatch()

  const onSubmitExperiences = (e) => {
    e.preventDefault();

    const [
      { value: title },
      { value: company_name },
      { value: type },
      { value: monthStartDate },
      { value: yearStartDate },
      { value: monthEndDate },
      { value: yearEndDate },
      { value: description },
    ] = formRef.current;

    const skills = listSkill.join(",");

    console.table({
      title,
      company_name,
      type,
      monthStartDate,
      yearStartDate,
      monthEndDate,
      yearEndDate,
      description,
      skills,
    });

    appContext.loadLoadingBar();

    if(initValue != null){
      requestClient
        .put("/experiences", {
          id: initValue.id, 
          title,
          company_name,
          type,
          start_date: `${monthStartDate} ${yearStartDate}`,
          end_date: `${monthEndDate} ${yearEndDate}`,
          monthEndDate,
          yearEndDate,
          description,
          skills,
        })
        .then(({ data }) => {
          appContext.showWarning(data.message, "success");
          formRef.current.reset();
          setListSkill([]);
          onCompleteEdit()
          dispatch(experienceActionCreators.fetchData())
          revalidatePath(appContext.user.slug);
        })
        .catch((e) => {
          console.log(e.response);
        })
        .finally(appContext.closeLoadingBar);
    }else{
      requestClient
        .post("/experiences", {
          title,
          company_name,
          type,
          start_date: `${monthStartDate} ${yearStartDate}`,
          end_date: `${monthEndDate} ${yearEndDate}`,
          monthEndDate,
          yearEndDate,
          description,
          skills,
        })
        .then(({ data }) => {
          appContext.showWarning(data.message, "success");
          formRef.current.reset();
          setListSkill([]);
          revalidatePath(appContext.user.slug);
        })
        .catch((e) => {
          console.log(e.response);
        })
        .finally(appContext.closeLoadingBar);
    }

  };

  return (
    <form className="mt-4 w-full" ref={formRef} onSubmit={onSubmitExperiences}>
      <div className="container-horizontal">
        <div className="input-group">
          <label>Title</label>
          <input
            type="text"
            placeholder="Title"
            className="outline-dark"
            defaultValue={initValue?.title ?? ""}
          />
        </div>
        <div className="input-group">
          <label>Company Name</label>
          <input
            type="text"
            placeholder="Title"
            className="outline-dark"
            defaultValue={initValue?.company_name ?? ""}
          />
        </div>
      </div>
      <div className="input-group">
        <label>Type</label>
        <input
          type="text"
          name="Type"
          placeholder="Type"
          className="outline-dark"
          defaultValue={initValue?.type ?? ""}
        />
      </div>
      <div className="container-horizontal">
        <div className="input-group">
          <label>Start Date</label>
          <div className="grid grid-cols-12 gap-4 w-full">
            <input
              type="text"
              placeholder="Month"
              className="outline-dark col-span-8"
              defaultValue={initValue?.start_date.split(" ")[0]}
            />
            <input
              type="number"
              placeholder="Year"
              className="outline-dark col-span-4"
              defaultValue={initValue?.start_date.split(" ")[1]}
            />
          </div>
        </div>
        <div className="input-group">
          <label>End Date</label>
          <div className="grid grid-cols-12 gap-4 w-full">
            <input
              type="text"
              placeholder="Month"
              className="outline-dark col-span-8"
              defaultValue={initValue?.end_date.split(" ")[0]}
            />
            <input
              type="number"
              placeholder="Year"
              className="outline-dark col-span-4"
              defaultValue={initValue?.end_date.split(" ")[1]}
            />
          </div>
        </div>
      </div>

      <div className="input-group">
        <label>Experience Description</label>
        <textarea
          className="outline-dark"
          placeholder="Experience Description"
          defaultValue={initValue?.description ?? ''}
        />
      </div>

      <div className="input-group">
        <label>Experience Skills</label>
        <input
          className="underlined-dark"
          type="text"
          placeholder="Press Tab or Enter after typing to add new Skills"
          onChange={(e) => setSkillInput(e.target.value)}
          onKeyUpCapture={onAddSkills}
          onKeyDownCapture={(e) =>
            addPreventDefaultOnSomeKey(e, ["Tab", "Enter"])
          }
          value={skillInput}
        />
        <div className="container-tags">
          {listSkill.map((skill, idx) => (
            <span
              className="tag"
              key={idx}
              onDoubleClick={() => removeSkill(skill)}
            >
              {skill}
            </span>
          ))}
        </div>
      </div>

      <button type="submit" className="primary-dark ml-auto block">
        Add Experience
      </button>
    </form>
  );
};

export default FormAddExperience;
