import React, { useState } from "react";

export const AppContext = React.createContext({
  isLoadingBarOn: false,
  loadLoadingBar: () => {},
  closeLoadingBar: () => {},

  warning: {
    message: "",
    isWarningShowed: false,
    type: "success",
  },
  showWarning: (message, type) => {},

  user: {
    id: "",
    name: "",
    slug: "",
  },
  setUser: (name,id, slug) => {},
});

const AppContextProvider = ({ children }) => {
  const [loadingBar, setLoadingBar] = useState(false);
  const [warning, setWarning] = useState({
    message: "",
    isWarningShowed: false,
    type: "success",
  });

  const showWarning = (message, type) => {
    setWarning((prevState) => {
      return { ...prevState, message, type, isWarningShowed: true };
    });

    setTimeout(() => {
      setWarning((prevState) => {
        return {
          ...prevState,
          message: "",
          type: "success",
          isWarningShowed: false,
        };
      });
    }, 2000);
  };

  const [user, setUser] = useState({
    id: "",
    name: "",
    slug: ""
  });

  const createUser = (name,id, slug) => {
    setUser({
      id: id,
      name: name,
      slug: slug,
    });
  }

  return (
    <AppContext.Provider
      value={{
        isLoadingBarOn: loadingBar,
        loadLoadingBar: () => {
          setLoadingBar(true);
        },
        closeLoadingBar: () => {
          setLoadingBar(false);
        },

        warning: warning,
        showWarning: showWarning,

        user: user,
        setUser: createUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
