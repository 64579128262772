import { useEffect, useState } from "react";
import style from "./Switch.module.css";

const Switch = ({ id, defaultValue, onChanged }) => {
  const [value, setValue] = useState(defaultValue ?? false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChangeValue = async (e) => {
    const result = onChanged ? onChanged(!value) : true;

    if (result) {
      setValue(!value);
    }
  };

  return (
    <div className={style["switch"]}>
      <div
        className={`${style["input-switch"]} ${value ? style["checked"] : ""}`}
        name="switch"
        id={id}
      />
      <label onClick={onChangeValue} htmlFor={id}></label>
    </div>
  );
};

export default Switch;
