import SidebarStyle from "./Sidebar.css";

import { AiFillProfile } from "react-icons/ai";
import { FaMedal, FaUserFriends } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { RiLogoutBoxRFill } from 'react-icons/ri';

import {MdOutlineWork} from 'react-icons/md';

import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const isSideBarActive = ({ isActive }) => {
    return isActive ? "active" : "";
  };

  return (
    <nav style={SidebarStyle.nav}>
      <ul className="h-full flex flex-col">
        <li>
          <NavLink to={"/"} className={isSideBarActive}>
            <CgProfile />
            <label>Profile</label>
          </NavLink>
        </li>
        <li>
          <NavLink to={"/projects"} className={isSideBarActive}>
            <AiFillProfile />
            <label>Project</label>
          </NavLink>
        </li>
        <li>
          <NavLink to={"/experiences"} className={isSideBarActive}>
            <MdOutlineWork />
            <label>Experience</label>
          </NavLink>
        </li>
        <li>
          <NavLink to={"/certificates"} className={isSideBarActive}>
            <FaMedal />
            <label>Certificates</label>
          </NavLink>
        </li>
        <li className="mt-auto">
          <NavLink to={"/user"} className={isSideBarActive}>
            <FaUserFriends />
            <label>User</label>
          </NavLink>
        </li>
        <li className="">
          <NavLink to={"/login"} className={isSideBarActive} onClick={() => {
            localStorage.removeItem("token");
          }}>
            <RiLogoutBoxRFill />
            <label>Logout</label>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
