import { useContext, useEffect, useRef, useState } from "react";
import requestClient from "../../../../../external/request-client-api/RequestClient";
import { AppContext } from "../../../../../external/store/AppContext";
import ListProjectStyle from "./ListProject.css";

import { MdEdit, MdDelete } from "react-icons/md";
import Overlay from "../../overlay/Overlay";
import FormProject from "../form-project/FormProject";
import FormEditProject from "../form-edit-project/FormEditProject";
import { revalidatePath } from "../../../../../external/global-function/global-function";

const ListProject = ({projects, getData, experiences}) => {
  const [isDeletePopUpOn, setDeletePopUp] = useState(false);
  const [isEditPopUpOn, setEditPopUp] = useState(false);
  const [onChoosedProject, setOnChoosedProject] = useState(null);

  const appContext = useContext(AppContext);
  
  const openEditPopUp = (project) => {
    setOnChoosedProject(project);
    setEditPopUp(true);
  };

  const closeEditPopUp = () => {
    setEditPopUp(false);
  };

  const openDeletePopUp = (project) => {
    setOnChoosedProject(project);
    setDeletePopUp(true);
  };

  const closeDeletePopUp = () => {
    setDeletePopUp(false);
  };

  const onDeleteClicked = () => {
    appContext.loadLoadingBar();
    requestClient
      .delete(`projects?project_id=${onChoosedProject.id}`)
      .then(({ data }) => {
        appContext.showWarning(data.data.message, "success");
        getData();
        revalidatePath(appContext.user.slug);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        appContext.closeLoadingBar();
        closeDeletePopUp();
      });
  };
  
  const onEditDone = () => {
    closeEditPopUp();
    getData();
  }

  return (
    <div
      className="container-project"
      style={ListProjectStyle.containerProject}
    >
      {projects.map((project) => (
        <div key={project.id} className="project">
          <img src={project.cover} alt="" />
          <div className="detail">
            <h3 className="title">{project.name}</h3>
            <p className="description">{project.description}</p>

            <div className="actions">
              <button className="circle-button primary-dark" onClick={() => openEditPopUp(project)}>
                <MdEdit />
              </button>

              <button
                className="circle-button error"
                onClick={() => openDeletePopUp(project)}
              >
                <MdDelete />
              </button>
            </div>
          </div>
        </div>
      ))}
      {isDeletePopUpOn ? (
        <Overlay>
          <div className="card flex flex-col items-center justify-center">
            <img
              src="https://general.itsmysite.my.id/images/wTMNYVAMxw3DVBxRe3VZ9o9f9Sblgf7xMToV0vZg.svg"
              alt="illustration delete"
              className="mb-6"
            />
            <h3 className="text-xl w-80 text-center mb-6">
              Are you sure, you want to delete the project ?
            </h3>
            <div className="actions flex w-full">
              <button className="text-button flex-1" onClick={closeDeletePopUp}>
                Cancel
              </button>
              <button className="button error flex-1" onClick={onDeleteClicked}>
                Delete
              </button>
            </div>
          </div>
        </Overlay>
      ) : (
        ""
      )}
      {isEditPopUpOn ? (
        <Overlay>
          <div className="card">
            <h3 className="text-xl mb-6 font-bold">Edit Project</h3>
            <FormEditProject project={onChoosedProject} closePopUpAction = {closeEditPopUp} onEditDone={onEditDone} experiences={experiences}/>
          </div>
        </Overlay>
      ) : (
        ""
      )}
    </div>
  );
};

export default ListProject;
