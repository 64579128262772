import axios from "axios";
console.log('init request client outside function');
const RequestClient = () => {
    console.log('init request client');
    const keyToken = localStorage.getItem("token");

    const requestClient = axios.create({
        // baseURL: process.env.NODE_ENV === 'development' ? 'https://general.itsmysite.my.id/api/' :  "https://general.itsmysite.my.id/api/",
        baseURL: 'https://vps.whodevs.codes/api/',
        // baseURL: process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/' :  "https://api.whodevs.com/api/",
        timeout: 50000,
        headers: {
            'Authorization': `Bearer ${keyToken}`, 
            'accept': 'application/json'
        },
    });

    requestClient.interceptors.request.use((config) => {
        const keyToken = localStorage.getItem("token");
        config.headers.Authorization = keyToken ? `Bearer ${keyToken}` : '';

        return config;
    })

    
    return requestClient;
}

const requestClient = RequestClient();

export default requestClient;